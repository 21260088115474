import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../styles/styles.css';

import CvElement from './CV_element';

const CV = () => {

  const finalValue = () => {
    return (
      <Row className="cv-container" id="cv-section">
        <Col className="cv">
          <h1 className="primary-color">Curriculum Vitae</h1>
          <br></br>
          <h3 className="fw-normal">Education</h3>
          <br></br>
          <ul>
            <CvElement title="EPFL - Bachelor Microengineering" dates="2016 - 2019" />
            <CvElement title="EPFL extension school - Web Application Development" dates="2021" />
            <CvElement title="UNIGE - Bachelor Computer Science" dates="2020 - 2023" />
          </ul>

          <h3 className="fw-normal">Work Experience</h3>
          <br></br>
          <ul>
            <CvElement title="Internship at IBM’s RnD department" dates="June 2015" description="One month of internship to analyze different methods of detection and corrections of bugs in computer programs and give a presentation of these findings." />
            <CvElement title="Advisor at Swiss Risk and Care" dates="2020 - present" description="Offering multilingual (English, Mandarin) HR services for international corporate clients." />
          </ul>

          <h3 className="fw-normal">Skills</h3>
          <br></br>
          <ul>
            <CvElement title="HTML / CSS (Sass)" dates="Advanced"/>
            <CvElement title="Javascript (ES6) / jQuery" dates="Intermediate"/>
            <CvElement title="React.JS (+ redux basics)" dates="Advanced"/>
            <CvElement title="Ruby on Rails" dates="Advanced"/>
            <CvElement title="Node.js (express.js)" dates="Intermediate-Advanced"/>
            <CvElement title="SwiftUI (iOS app development)" dates="Beginner-Intermediate"/>
            <CvElement title="Python / Matlab / Swift / C / C++ / C#" dates="Intermediate"/>
          </ul>

          <h3 className="fw-normal">Languages</h3>
          <br></br>
          <ul>
            <CvElement title="English" dates="Native" />
            <CvElement title="French" dates="Fluent (C2)" />
            <CvElement title="Mandarin" dates="Proficient (C1)" />
            <CvElement title="Italian" dates="Proficient (B2)" />
          </ul>
        </Col>
      </Row>
    )};

    return finalValue();
};

export default CV;