import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../styles/styles.css';
import webdog_image from '../assets/webdog.png';
import epuck_image from '../assets/e-puck2.jpeg';

const Projects = () => {

  const finalValue = () => {
    return (
      <Row className="projects-container" id="portfolio">
        <Col className="projects">
          <h1 className="primary-color">My Projects</h1>
          <br></br>
          <h3 className="project-title">Web Dog</h3>
          <p>Capstone project of EPFLx web development program. <a href="https://chilling-caverns-73628.herokuapp.com/ ">Live Demo (deployed on Heroku)</a> </p>
          <p>Webdog is the website that allows a dog training center to easily interact and organize classes with their clients. The website has a <strong>forum</strong> for clients to ask questions and receive advice and a <strong>blog</strong> for admins and trainers to publish well-thought out articles. Through the <strong>chat service</strong> on the website, any registered user of the website can communciate instanteously with others. The <strong>booking</strong> of events can be done through the Calendly third-party API. The website has <strong>4 levels of authorizations</strong>: admin, trainer, client and anonymous(unregistered).</p>
          <p></p>
          <ul className="tech-stack-list">
            <li className="tech-stack-item">Ruby on Rails</li>
            <li className="tech-stack-item">Action Cable / Web Sockets / Redis</li>
            <li className="tech-stack-item">PostgresQL / SQLite</li>
            <li className="tech-stack-item">Bulma / Sass</li>
            <li className="tech-stack-item">AWS S3</li>
          </ul>
          <div className="image-container">
            <img className="project-image" src={webdog_image} alt="Web Dog preview"></img>
          </div>

          <br></br>
          <br></br>

          <h3 className="project-title">Robot - E-puck 2</h3>
          <p><a href="https://github.com/ItsZolaZeta/E-puck2">Github repository</a></p>
          <div className="image-text-container">
            <p>Programming in <strong>C</strong> the E-puck 2 robot to be able to follow a black line (not necessarily straight) and go around any obstacle along its path. Calibration of camera and multiple sensors. Within feedback loop, coded a <strong>PID controller</strong> system to auto-correct for its own error.</p>
            <div className="image-container">
              <img className="project-image epuck" src={epuck_image} alt="E-puck 2 robot"></img>
            </div>
          </div>

          <br></br>
          <br></br>

          <h3 className="project-title">Ecommerce Inventory Management System</h3>
          <p><a href="https://github.com/ItsZolaZeta/EcomInventory">Github repository</a></p>
          <div className="image-text-container">
            <p>A windows application coded in <strong>C#</strong>, using <strong>.NET</strong> template of <strong>WinForms</strong>. The application uses an <strong>SQL database</strong> to store and update information.</p>
            <div className="image-container">
              {/* <img className="project-image epuck" src={epuck_image} alt="E-puck 2 robot"></img> */}
            </div>
          </div>

          <br></br>
          <br></br>

          <h1 className="primary-color">Side Projects that may one day be completed...</h1>
          <br></br>
          <h3 className="project-title">Cordial Dogs</h3>
          <p>A full-stack website with separate Frontend and Backend. Front-end using <strong>React.js</strong> and Backend using <strong>Node.js (express.js) and Javascript (ES6)</strong>, communication between the two in development through proxy.</p> 
          <p>The website is a simple blog with all <strong>CRUD operations</strong>.</p>   
          <p><a href="https://github.com/ItsZolaZeta/cordial_dogs">Github respository</a></p>
          <br></br>
          <h3 className="project-title">Gamers Assemble</h3>
          <p>A full-stack website with separate Frontend and Backend. Frontend using <strong>React.js</strong> and Backend using <strong>Node.js (express.js) and Javascript (ES6)</strong>, communicating via proxy. </p>
          <p>A place where gamers who wish to find other gamers to play with can go. It will have its own matchmaking system (backend, SQL optimised hopefully) which match people together based on the criteria chosen by each user (level in game, what game, gender, age, region, etc). Potentially could separate this based on what "type" of people they want to find, casual friends or competitive to grind. Gamers can become friends on the website, comparable to facebook friends. They will be able to chat in private and see what game they're playing. It will also have group functionalities, where people can join groups and chat to meet new like-minded people.</p>
          <p><a href="https://github.com/ItsZolaZeta/gamers-assemble">Github respository</a></p>
          <br></br>
          <h3 className="project-title">Dog Exercise App </h3>
          <p>An iOS app. Frontend using <strong>SwiftUI</strong> and maybe eventually a separate backend that can be used for a frontend website as well.</p>
          <p>An app for dog trainers to publish useful exercises that be done with dogs, each exercise and its steps has a description and photos/videos. Users can search based on keywords for an exercise, favorite it and rate it. Exercises can be saved for offline usage through <strong>CoreData</strong> and shared with other devices via <strong>CloudKit</strong>.</p>
          <p><a href="https://github.com/ItsZolaZeta">Link coming soon</a></p>
          <br></br>
          <h3 className="project-title">Plant App </h3>
          <p>An iOS app coded using <strong>SwiftUI</strong> and the usage of a <strong>third-party API</strong> for AI recognition of the plant name.</p>
          <p>An app for plant lovers to be able to take a photo and find out the name of the plant and all relevant important details. Plants can be added to each user's "garden" where they will receive reminders of when and how much they need to water. Each plant's lifecycle and progress can be tracked in app and updated.</p>
          <p><a href="https://github.com/ItsZolaZeta">Link coming soon</a></p>

          <br></br>
          <br></br>

          <h1 className="primary-color">Mini Projects</h1>
          <br></br>
          <h3 className="project-title">Win at Poker</h3>
          <p>A <strong>python</strong> script with a simple graphic user interface that simulates a 1v1 poker match and helps you determine mathematically your probability of winning based on your hand and what's on the table.</p>
          <p><a href="https://github.com/ItsZolaZeta">Link coming soon</a></p>
          <br></br>
          <h3 className="project-title">Mario Plan</h3>
          <p>Small project to put to practice basic <strong>React + Redux</strong> techniques and integrate <strong>firebase</strong> as well.</p>
          <p><a href="https://github.com/ItsZolaZeta">Link coming soon</a></p>
          <br></br>
          <h3 className="project-title">CSS (Sass)</h3>
          <p>Simple mini project using the atomic elements approach to learn how to writing <strong>Sass</strong>.</p>
          <p><a href="https://github.com/ItsZolaZeta/HTML-CSS-Sass-">Github respository</a></p>
          <br></br>
          <h3 className="project-title">Javascript DOM manipulation</h3>
          <p>Small project where all interactive aspects are coded in <strong>Javascript</strong>.</p>
          <p><a href="https://github.com/ItsZolaZeta/Javascript-DOM-Manipulation">Github respository</a></p>
          <br></br>
          <h3 className="project-title">Simple Pinterest</h3>
          <p>Small project to put basic <strong>Ruby on Rails</strong> notions in practice.</p>
          <p><a href="https://github.com/ItsZolaZeta/Simple-Pinterest">Github respository</a></p>
          <br></br>
          <h3 className="project-title">Pro Tips</h3>
          <p>Simple Ruby on Rails backend website made using <strong>Carrierwave</strong> and <strong>Amazon Web Services</strong> services integrated.</p>
          <p><a href="https://github.com/ItsZolaZeta/Pro-Tips">Github respository</a></p>
        </Col>
      </Row>
    )};


    return finalValue();
};

export default Projects;