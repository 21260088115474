import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import '../styles/styles.css';
import logo_image from '../assets/logo.png';

import About from './About';
import Intro from './Intro';
import Projects from './Projects';
import CV from './CV';
import Contact from './Contact';

const NavBar = () => {

  const finalValue = () => {
    return (
      <Container fluid>

        <Row className="align-items-start">
          {/* disappear on small screens - hamburger menu */}
          <Col sm={2} className="vertical-navbar d-none d-sm-block">
            <Image src={logo_image} fluid />
            <Nav defaultActiveKey="/home" className="flex-column">
              <Nav.Link href="#about-section">About</Nav.Link>
              <Nav.Link href="#portfolio" eventKey="link-2">Projects</Nav.Link>
              <Nav.Link href="#cv-section" eventKey="link-1">CV</Nav.Link>
              <Nav.Link href="#contact" eventKey="link-3">Contact</Nav.Link>
            </Nav>
          </Col>
          <Col className="main-content">
            <Intro />
            <About />
            <Projects />
            <CV />
            <Contact />
          </Col>
        </Row>
      </Container>
    )};


    return finalValue();
};

export default NavBar;