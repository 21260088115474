import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../styles/styles.css';
// import zuka from '../assets/zuka.JPG';
import zuka_puppy from '../assets/zuka_puppy.JPG';

const About = () => {

  const finalValue = () => {
    return (
      <Row className="about-me-container" id="about-section">
        <Col className="about-me">
          <h1 className="primary-color">About Me</h1>
          <br></br>
          <div className="image-text-container">
          <div className="about-text">
            <p>Hello! My name is Tatiana and I enjoy programming and building things for the internet!</p>
            <p>I love working on personal projects in areas that interest me, like building and programming robots, web development or iOS app development.</p>
            <p>One of my hobbies is playing video games, to that end, I built my own PC and later on, those of multiple friends as well.</p>
            <p>If I'm not behind a computer screen, you'll find me outside hiking in the mountains and having fun with my dog, Zuka.</p>
          </div>
          {/* <div className="about-images">  */}
            {/* <div className="image-container">
              <img className="project-image" src={zuka} alt="Zuka Adult"></img>
            </div> */}
            <div className="image-container">
              <img className="project-image" src={zuka_puppy} alt="Zuka Puppy"></img>
            </div>
          </div>
        </Col>
      </Row>
    )};


    return finalValue();
};

export default About;