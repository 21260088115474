import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../styles/styles.css';

const Intro = () => {

  const finalValue = () => {
    return (
      <Row className="home-title align-items-center" id="home-section">
        <Col className="introduction jumbotron"> 
          <h1 className="display-3">Hey,</h1>
          <h1 className="display-3">I'm <span className="primary-color">Tatiana,</span></h1>
          <h1 className="display-3">web developer</h1>
          <br></br>
          <p className="fs-6">Full-Stack Developer / Programmer</p>
        </Col>
      </Row>
    )};


    return finalValue();
};

export default Intro;