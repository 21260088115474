import React from 'react';
import Row from 'react-bootstrap/Row';
import '../styles/styles.css';

const CvElement = (props) => {

  const finalValue = () => {
    if(props.description) {
      return (
        <Row className="cv-element-container">
          <div className="cv-element-header">
            <h6 className="fw-normal">{props.title}</h6>
            <p className="blockquote-footer">{props.dates}</p>
          </div>
          <div>
            <p className="cv-description fs-6">{props.description}</p>
          </div>
        </Row>
      )}
    return (
      <Row className="cv-element-container">
        <div className="cv-element-header">
          <h6 className="fw-normal">{props.title}</h6>
          <p className="blockquote-footer">{props.dates}</p>
        </div>
      </Row>
    )
  }; 

    return finalValue();
};

export default CvElement;