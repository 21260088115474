import React from 'react';
import { useForm } from '@formspree/react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../styles/styles.css';

const Contact = () => {

  const [state, handleSubmit] = useForm("xvodezne");

  if (state.succeeded) {
      return (
        <Row className="contact-container" id="contact">
          <Container className="contact-me">
            <h1 className="primary-color">Contact Me</h1>
            <br></br>
            <Container className="contact-thanks">
              <h4>Thanks for contacting me!</h4>
            </Container>
          </Container>
        </Row>
        );
  }

  const finalValue = () => {
    return (
      <Row className="contact-container" id="contact">
        <Container className="contact-me">
          <h1 className="primary-color">Contact Me</h1>
          <br></br>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="Form.Name">
              {/* <Form.Label>Name</Form.Label> */}
              <Form.Control type="input" placeholder="Name" name="name"/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="Form.Email">
              {/* <Form.Label>Email</Form.Label> */}
              <Form.Control type="email" placeholder="Email" name="email"/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="Form.Message">
              {/* <Form.Label>Message</Form.Label> */}
              <Form.Control as="textarea" name="message" rows={4} placeholder="Message"/>
            </Form.Group>

            <Button variant="primary" type="submit" disabled={state.submitting}>
              Submit
            </Button>
          </Form>
        </Container>
      </Row>
    )};


    return finalValue();
};

export default Contact;